import React from "react"
import { Link } from "gatsby"

function Header() {
  return (
    <header className="header">
      <h2 className="header__title">
        <Link to={"/"}>ハイパー忍法帖</Link>
      </h2>
      <small>小説と漫画と映画と投資</small>
    </header>
  )
}

export default Header
